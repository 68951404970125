import { API_URL } from '../constants/config';
const apiEndpoints = {
  authenticate: API_URL + 'profile/login',
  refreshToken: API_URL + 'profile/token',
  forgotPassword: API_URL + 'profile/user/forgot-password-email',
  individualMemberData: API_URL + 'organisations/organisation_member_detail',
  ecards: API_URL + 'ecard/send_bulk_ecard',
  ecard: API_URL + 'ecard/generate',
  resetPassword: API_URL + 'profile/user/reset-password',
  userData: API_URL + 'profile/user',
  userList: API_URL + 'profile/member',
  products: API_URL + 'masterdata/product',
  providerList: API_URL + 'masterdata/provider',
  providerListV1: API_URL + 'masterdata/provider_v1',
  tpaList: API_URL + 'masterdata/tpa',
  tpaInsurerMapping: API_URL + 'masterdata/tpa-provider-mapping',
  clientDetails: API_URL + 'organisations/organisation',
  productDetails: API_URL + 'policies/product-subscription',
  clientList: API_URL + 'organisations/organisation',
  clientListV1: API_URL + 'organisations/organisation_v1',
  updateMemberStatus: API_URL + 'organisations/member_status',
  memberDataReview: API_URL + 'organisations/member_review_v1/',
  memberDataReviewV1: API_URL + 'organisations/member_review_v1/',
  celeryTaskStatus: API_URL + 'organisations/celery_tasks_status?task_id=',
  memberDataSubmit: API_URL + 'organisations/member_upload/',
  downloadMIS: API_URL + 'organisations/download_mis/',
  endorsementMIS: API_URL + 'endorsement/member_mis',
  individualMIS: API_URL + 'individual/individual_mis',
  corporateMIS: API_URL + 'organisations/organisation_mis',
  coverageList: API_URL + 'masterdata/coverage-type',
  getRoles: API_URL + 'profile/role',
  userManagementRoles: API_URL + 'profile/user_management_role',
  editRole: API_URL + 'profile/user-role-edit',
  user: API_URL + 'profile/user-member',
  member: API_URL + 'organisations/organisation_member',
  memberV1: API_URL + 'organisations/organisation_member_v1',
  memberRemove: API_URL + 'organisations/member_remove',
  sale: API_URL + 'organisations/sale',
  getClaims: API_URL + 'claims/employer/organisation_claims',
  pendingClaims: API_URL + 'claims/pending_claims',
  populateClaims: API_URL + 'claims/claims_data_populate',
  claims: API_URL + 'claims/claims',
  claimsv1: API_URL + 'claims/claims_v1',
  downloadClaimsMIS: API_URL + 'claims/mis',
  memberProduct: API_URL + 'organisations/product_subscription',
  choices: API_URL + 'organisations/filter',
  policyUpload: API_URL + 'policies/policy_file',
  productBenefits: API_URL + 'masterdata/benifit',
  clientPolicyBenefits: API_URL + 'masterdata/client_policy_benifit',
  productBenefitTemplate: API_URL + 'masterdata/benifit_template',
  clientProductBenefit: API_URL + 'masterdata/client_product_benifit',
  resetClientProductBenefit: API_URL + 'masterdata/set_client_default_benefits',
  getClientBenefits: API_URL + 'masterdata/get_client_product_benifit',
  subscriptionBenefits: API_URL + 'masterdata/subscription_benefits',
  imageUpload: API_URL + 'utils/upload_image_to_s3',
  uploadToS3: API_URL + 'utils/upload_to_s3',
  uploadFile: API_URL + 'utils/upload_file_to_s3',
  dependantRemove: API_URL + 'organisations/employer/member_remove',
  stats: API_URL + 'dashboard/dashboard_operations',
  requestsApproval: API_URL + 'endorsement/employer/policy-endorsement',
  endorsements: API_URL + 'endorsement/policy-endorsement',
  endorsement: API_URL + 'endorsement/detail',
  organisationMIS: API_URL + 'organisations/member_mis',
  individualList: API_URL + 'individual/individual',
  individualProducts: API_URL + 'individual/subscribed_products',
  populateIndividualClaims: API_URL + 'claims/individual_claims_data_populate',
  coupons: API_URL + 'masterdata/coupon',
  couponManager: API_URL + 'masterdata/get_product_coupon_count',
  uploadCouponCodes: API_URL + 'masterdata/upload_coupon',
  deleteCouponCodes: API_URL + 'masterdata/delete_coupon',
  downloadCouponMIS: API_URL + 'masterdata/coupon_mis',
  individualMember: API_URL + 'individual/individual_member_detail',
  corporateListing: API_URL + 'individual/get_organisation',
  individualMembers: API_URL + 'individual/individual_member',
  addProductToIndividual: API_URL + 'individual/product',
  removeIndividualDependent: API_URL + 'individual/remove_dependent',
  communicationTypes: API_URL + 'communicator/organisation_communication',
  organisationDemo: API_URL + 'communicator/organisation_demo_type',
  communicationConsent: API_URL + 'communicator/organisation_consent',
  memberCommunication: API_URL + 'communicator/communication_member',
  communicationEmail: API_URL + 'communicator/organisation_email_send',
  certificateOfInsurance: API_URL + 'product_integration/digit_grp/bulk_coi',
  partnerDetails: API_URL + 'partners/partner',
  partnerList: API_URL + 'partners/partner',
  partnerLink: API_URL + 'partners/partner-link',
  bulkMembersDelete: API_URL + 'organisations/bulk_member_delete/',
  endorseAll: API_URL + 'endorsement/policy-endorsement-all',
  emailToAll: API_URL + 'communicator/organisation_email_send_all',
  organisationDetail: API_URL + 'organisations/organisation_detail',
  endorsementProgress: API_URL + 'endorsement/endorsement-progress',
  productIntegrationLogs: API_URL + 'product_integration/logs',
  activateProduct: API_URL + 'product_integration/activate_product',
  bulkTemplateEmployer: API_URL + 'endorsement/generate_template/',
  singleMemberEdit: API_URL + 'organisations/single_member_upload/',
  hardDeleteMembber: API_URL + 'organisations/hard_member_remove',
  makeMemberActive: API_URL + 'organisations/member_active',
  singleMemberProducts: API_URL + 'organisations/organisation_product_update',
  partnerListing: API_URL + 'broker/broker',
  partnerProducts: API_URL + 'broker/broker-product-provider',
  brokerFilter: API_URL + 'broker/filter',
  brokerClientProducts: API_URL + 'broker/broker-product',
  brokerConfig: API_URL + 'broker/broker-config',
  brokerUsers: API_URL + 'broker/broker-user-member',
  brokerProduct: API_URL + 'broker/broker-product',
  brokerProvider: API_URL + 'broker/broker-provider',
  brokerMIS: API_URL + 'broker/broker-mis',
  providerProductPlan: API_URL + 'masterdata/provider-product-plan',
  notificationSetting: API_URL + 'notification/subscription',
  notification: API_URL + 'notification/notification',
  notificationCount: API_URL + 'notification/count',
  endorsementCount: API_URL + 'organisations/organisation_endorsement_count',
  endorsementValidation: API_URL + 'endorsement/endorsement_validation',
  deletePartnerProduct: API_URL + 'policies/remove_subscribed_product',
  claimDocuments: API_URL + 'documents/documents',
  claimCommunication: API_URL + 'claims/communication',
  organisationConfig: API_URL + 'organisations/organisation_config',
  partnerDataPopulate: API_URL + 'partners/partner-data-populate',
  brokerSettingConfig: API_URL + 'broker/broker-setting-config',
  clientSettingConfig: API_URL + 'organisations/organisation_settings_config',
  clientEndorsementBatches: API_URL + 'endorsement/organisation_batches',
  clientProductEndorsementPremium: API_URL + 'transactions/organisation_transactions',
  clientEndorsementBalanceCheck: API_URL + 'endorsement/endorsements_transactions',
  clientProductBatchTransactions: API_URL + 'transactions/product_transactions',
  clientProductBatchTransactionsSummary: API_URL + 'transactions/product_transactions_summary',
  clientEndorsementBatch: API_URL + 'endorsement/endorsements_batch',
  clientProductEndorsementBatch: API_URL + 'endorsement/endorsements_product_batch',
  clientTxListing: API_URL + 'transactions/product_cd_balance_recharge_history',
  rechargeCDBalance: API_URL + 'transactions/recharge_product_cd_balance',
  endorsementStatus: API_URL + 'endorsement/endorsements_status',
  endorsementReview: API_URL + 'endorsement/endorsements_review',
  endorsementUpload: API_URL + 'endorsement/endorsements_upload',
  transactionSteps: API_URL + 'organisations/organisation_config_validation',
  transactionMIS: API_URL + 'organisations/organisation_transaction_member_mis',
  transactionMISReview: API_URL + 'organisations/organisation_transaction_member_review',
  transactionMISSubmit: API_URL + 'organisations/organisation_transaction_member_upload',
  claimsAnalytics: API_URL + 'claims/analytics',
  memberProductUpdate: API_URL + 'organisations/member_product_update',
  fetchManipalDocs: API_URL + 'product_integration/manipal_signa/fetch_fti_policy_docs',
  manipalPolicyDocs: API_URL + 'organisations/celery_tasks_status',
  dateOfAdmissionValidation: API_URL + 'claims/date_of_admission_validation',
  userDeletion: API_URL + 'profile/user-delete-validation',
  moveEndrosementBatch: API_URL + 'endorsement/move_endorsement_to_batch',
  claimsProcessorDocumentListing: API_URL + 'documents/processor_documents',
  claimsDocumentDownload: API_URL + 'documents/claim_documents_download',
  memberWiseMIS: API_URL + 'reports/finance_mis',
  inidividualMIS: API_URL + 'reports/individual_mis',
  processorClaimsDocumentDownload: API_URL + 'documents/processor_claim_documents_download',
  offers: API_URL + 'masterdata/offer',
  brokerOfferPlans: API_URL + 'broker/su-broker-offer-plan',
  OffersPlans: API_URL + 'masterdata/offers_plans_list',
  organisationOffersPlans: API_URL + 'organisations/organisation_offers_plans',
  partnerOffersPlans: API_URL + 'partners/partner-offers-plans',
  transactionCDDownload: API_URL + 'transactions/download_cd_statement',
  providerServiceTransaction: API_URL + 'transactions/provider_service_transaction',
  brokerPlanOfferList: API_URL + 'broker/broker-offer-plan-list',
  claimSync: API_URL + 'claims/execute_claims_api',
  claimConfig: API_URL + 'claims/claims_api_config',
  ssoAuth: API_URL + 'profile/sso/callback',
  ssoInfo: API_URL + 'profile/sso/user-info',
  bulkAssignProduct: API_URL + 'organisations/bulk_assign_product',
  logout: API_URL + 'profile/logout',
  recordTransaction: API_URL + 'transactions/provider_service_transaction',
};

export default apiEndpoints;
